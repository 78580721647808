import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  getPackages,
  getPackageDetail,
  postPackage,
  putPackage,
  getPackageMember,
  getPackageMemberV2,
  getProductsMember
} from './packageAPI';

const initialState = {
  list: {
    data:[],
    total:0
  },
  total: 0,
  detail: {},
  status: 'idle',
  error: undefined,
  productsMember: {
    data:[],
    total:0
  },
  status_products: 'idle'
};

export const getPackagesAsync = createAsyncThunk(
  'packages',
  async (params, thunkAPI) => {
    try {
      const response = await getPackages(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      console.log(error);
      // return thunkAPI.rejectWithValue(error.response.data)
    }
  }
);

export const getPackagesMemberAsync = createAsyncThunk(
  'packagesMember',
  async (params, thunkAPI) => {
    try {
      const response = await getPackageMemberV2(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      console.log(error);
      // return thunkAPI.rejectWithValue(error.response.data)
    }
  }
);

export const getProductsMemberAsync = createAsyncThunk(
  'products member',
  async (params, thunkAPI) => {
    try {
      const response = await getProductsMember(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      console.log(error);
      // return thunkAPI.rejectWithValue(error.response.data)
    }
  }
);

export const getPackageDetailAsync = createAsyncThunk(
  'packageDetail',
  async (id, thunkAPI) => {
    try {
      const response = await getPackageDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
);
export const postPackageAsync = createAsyncThunk(
  'postPackage',
  async (data, thunkAPI) => {
    try {
      const response = await postPackage(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
);
export const putPackageAsync = createAsyncThunk(
  'putPackage',
  async (data, thunkAPI) => {
    try {
      const response = await putPackage(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
);
export const putPackageStatusAsync = createAsyncThunk(
  'putPackageStatus',
  async (data, thunkAPI) => {
    try {
      const response = await putPackage(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
);

export const packageSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    setIdleStatus: state => {
      state.status = "idle"
    },
    clearPackageDetail: state => {
      state.detail = undefined;
    },
    clearPackages: state => {
      state.list = initialState.list;
      state.productsMembersList = initialState.productsMembersList;
    },
    updatePackageList: (state, action) => {
      state.list = {
        data: action.payload,
        total: action.payload.length
      };
    },
    updatePackageDetail: (state, action) => {
      state.detail = action.payload;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getPackagesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPackagesAsync.fulfilled, (state, action) => {
        let result = {
          data: action.payload?.result.data, 
          total: action.payload?.result?.totalRecord,
        }
        state.list = result; 
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getPackagesAsync.rejected, (state, action) => {
        state.status = 'idle';
        message.error("Lấy dữ liệu gói dinh dưỡng thất bại!")
        state.error = {
          payload: action.payload,
          title: "getPackages"
        }
      })
      .addCase(getPackagesMemberAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPackagesMemberAsync.fulfilled, (state, action) => {
        let result = action.payload?.result
        state.list = result; 
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getPackagesMemberAsync.rejected, (state, action) => {
        state.status = 'idle';
        message.error("Lấy dữ liệu thất bại!")
        state.error = {
          payload: action.payload,
          title: "getPackages"
        }
      })
      .addCase(getPackageDetailAsync.pending, (state) => {
        state.status = 'detail_loading';
      })
      .addCase(getPackageDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload.result
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getPackageDetailAsync.rejected, (state, action) => {
        state.status = 'idle';
        message.error("Lấy dữ liệu thất bại!")
        state.error = {
          payload: action.payload,
          title: "getPackageDetail"
        }
      })
      .addCase(postPackageAsync.pending, (state) => {
        state.status = 'detail_loading';
      })
      .addCase(postPackageAsync.fulfilled, (state, action) => {
        state.detail = action.payload
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(putPackageAsync.pending, (state) => {
        state.status = 'update_loading';
      })
      .addCase(putPackageAsync.fulfilled, (state, action) => {
        state.detail = action.payload.result
        message.success("Cập nhật thành công")
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(putPackageAsync.rejected, (state, action) => {
        state.status = 'idle';
        message.error("Cập nhật thất bại!")
        state.error = {
          payload: action.payload,
          title: "putPackage"
        }
      })
      .addCase(putPackageStatusAsync.pending, (state) => {
        state.status = 'update_status_loading';
      })
      .addCase(putPackageStatusAsync.fulfilled, (state, action) => {
        state.detail = action.payload
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(putPackageStatusAsync.rejected, (state, action) => {
        state.status = 'idle';
        message.error("Status update failed!")
        state.error = {
          payload: action.payload,
          title: "putPackageStatus"
        }
      })
      .addCase(getProductsMemberAsync.pending, (state) => {
        state.status_products = 'loading';
      })
      .addCase(getProductsMemberAsync.fulfilled, (state, action) => {
        let result = action.payload?.result
        state.productsMember = {
          data: result,
          total: result.length
        }; 
        state.status_products = 'idle';
        state.error = undefined;
      })
      .addCase(getProductsMemberAsync.rejected, (state, action) => {
        state.status_products = 'idle';
        message.error("Lấy dữ liệu thất bại!")
        state.error = {
          payload: action.payload,
          title: "getPackages"
        }
      })
  },
});
export const selectPackages = state => state.packages.list
export const selectPackageDetail = state => state.packages.detail
export const packageStatus = state => state.packages.status
export const packagesError = state => state.packages.error
export const productsMembersList = state => state.packages.productsMember
export const status_products = state => state.packages.status_products

export const { 
  clearPackageDetail, 
  clearPackages, 
  setIdleStatus, 
  updatePackageList, 
  updatePackageDetail} = packageSlice.actions;

export default packageSlice.reducer;

