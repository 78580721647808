import axiosClient,  { getToken } from "../axiosClient"

const getCheckin = (params) => {
    return axiosClient.get("check-in", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getCheckinDetail = (id) => {
    return axiosClient.get("check-in/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postCheckin = (data) => {
    return axiosClient.post("check-in/v4", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}


const putCheckin = (data) => {
    return axiosClient.put("check-in/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const deleteCheckin = (id, data) => {
    return axiosClient.delete("check-in/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: data,
    })
}

const getPackages = (params) => {
    return axiosClient.get("packages", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getSession = (params) => {
    return axiosClient.get("enums/check-in-sessions", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getMember = (params) => {
    return axiosClient.get("check-in/members-not-yet-checkin", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getMemberList = (params) => {
    return axiosClient.get("members", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getNCCHadPackage = (params) => {
    return axiosClient.get(`check-in/ncc-have-package`, {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getPackageNotCheckIn = (params) => {
    return axiosClient.get(`check-in/packages-not-yet-checkedin`, {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getSessionNotCheckIn = (params) => {
    return axiosClient.get(`check-in/session-not-yet-checkedin`, {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}   

export {
    getCheckin,
    postCheckin,
    getCheckinDetail,
    putCheckin,
    deleteCheckin,
    getPackages,
    getSession,
    getMember,
    getMemberList,
    getNCCHadPackage,
    getPackageNotCheckIn,
    getSessionNotCheckIn
}