import {
    createAsyncThunk,
    // createAsytopicThunk,
    createSlice,
  } from "@reduxjs/toolkit";
  import { message } from "antd";
  import {getLogs } from "./logsAPI";
  
  const initialState = {
    list: {
        data: [],
        total: 0
    },
    status: "idle",
    error: undefined,
  };
  
  export const getLogsAsync = createAsyncThunk(
    "logs",
    async (payload, thunkAPI) => {
      try {
        const response = await getLogs(payload);
        // The value we return becomes the `fulfilled` action payload
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );
  
  
  export const logsSlice = createSlice({
    name: "logs",
    initialState,
    reducers: {
      setIdleStatus: (state) => {
        state.status = "idle";
      },
      clearLogs: (state) => {
        state.list = undefined;
      },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // ilogluding actions generated by createAsylogThunk or in other slices.
    extraReducers: (builder) => {
      builder
        .addCase(getLogsAsync.pending, (state) => {
          state.status = "loading";
        })
        .addCase(getLogsAsync.fulfilled, (state, action) => {
            console.log(" action.payload?.result",  action.payload?.result);
          state.list = {
              data: action.payload?.result?.data,
              total: action.payload?.result?.totalRecord
          };
          state.status = "idle";
          state.error = undefined;
        })
        .addCase(getLogsAsync.rejected, (state, action) => {
          state.status = "idle";
          if (action?.error?.name !== "AbortError") {
            message.error("Lấy dữ liệu logs thất bại!");
            state.error = {
              payload: action.payload,
              title: "getLogs",
            };
          }
        })
    },
  });
  
  export const selectLogs = (state) => state.logs.list;
  
  export const { clearLogs} = logsSlice.actions;
  
  export default logsSlice.reducer;
  