import axiosClient, { getToken } from "../axiosClient"

const getNotis = (params) => {
    return axiosClient.get("notifications", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getNotiDetail = (id) => {
    return axiosClient.get("notifications/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postNoti = (data) => {
    return axiosClient.post("notifications", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putNoti = (id, data) => {
    return axiosClient.put("notifications/" + id, data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putStatusNoti = ({id, values}) => {
    return axiosClient.put("notifications/" + id, values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getNotiTypes = (params) => {
    return axiosClient.get("enums/notification-types", {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const readAllNoti = (data) => {
    return axiosClient.post("notifications/read-all", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const updateReadNoti = (notificationId) => {
    return axiosClient.post(`notifications/read/${notificationId}`,{}, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getNotis,
    getNotiDetail,
    postNoti,
    putNoti,
    putStatusNoti,
    getNotiTypes,
    readAllNoti,
    updateReadNoti
}