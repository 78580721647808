import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
     getCategories, getProvinces,
} from './locationAPI';

const initialState = {
    listArea: undefined,
    listProvince: undefined,
    status: 'idle',
    error: undefined,
};

export const getAreasAsync = createAsyncThunk(
    'areas',
    async (params, thunkAPI) => {
        try {
            const response = await getCategories("area");
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const getProvincesAsync = createAsyncThunk(
    'provinces',
    async (params, thunkAPI) => {
        try {
            const response = await getProvinces();
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        setIdleStatus: state => {
            state.status = "idle"
        },
        clearAreaDetail: state => {
            state.detail = undefined;
        },
        clearAreas: state => {
            state.list = undefined;
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getAreasAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAreasAsync.fulfilled, (state, action) => {
                state.listArea = action.payload.result
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getAreasAsync.rejected, (state, action) => {
                state.status = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getAreas"
                    }
                }
            })
            .addCase(getProvincesAsync.fulfilled, (state, action) => {
                state.listProvince = action.payload.result
                state.status = 'idle';
                state.error = undefined;
            })
    },
});

export const selectAreas = state => state.location.listArea
export const areasStatus = state => state.location.status
export const areasError = state => state.location.error

export const selectProvinces = state => state.location.listProvince

export const { clearAreaDetail, clearAreas, setIdleStatus } = locationSlice.actions;

export default locationSlice.reducer;

