import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  getCategories,
  getCategoryDetail,
  putCategory,
  postCategory,
} from "./categoryAPI";

const initialState = {
  categories: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
};

export const getCategoriesAsync = createAsyncThunk(
  "categories",
  async (params, thunkAPI) => {
    try {
      const response = await getCategories(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getCategoryDetailAsync = createAsyncThunk(
  "categoryDetail",
  async (id, thunkAPI) => {
    try {
      const response = await getCategoryDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const postCategoryAsync = createAsyncThunk(
  "postCategory",
  async (data, thunkAPI) => {
    try {
      const response = await postCategory(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const putCategoryAsync = createAsyncThunk(
  "putCategory",
  async (data, thunkAPI) => {
    try {
      const response = await putCategory(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearCategoryDetail: (state) => {
      state.detail = undefined;
    },
    clearCategories: (state) => {
      state.categories = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getCategoriesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCategoriesAsync.fulfilled, (state, action) => {
        state.categories = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getCategoriesAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getCategories",
          };
        }
      })
      .addCase(getCategoryDetailAsync.pending, (state) => {
        state.status = "detail_loading";
      })
      .addCase(getCategoryDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getCategoryDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getCategory",
          };
        }
      })
      .addCase(postCategoryAsync.pending, (state) => {
        state.status = "detail_loading";
      })
      .addCase(postCategoryAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(putCategoryAsync.pending, (state) => {
        state.status = "update_loading";
      })
      .addCase(putCategoryAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        message.success("Update successfully!");
        state.status = "done";
        state.error = undefined;
      })
      .addCase(putCategoryAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Update failed!");
          state.error = {
            payload: action.payload,
            title: "putCategory",
          };
        }
      });
  },
});

export const selectCategories = (state) => state.categories.categories;
export const selectCategorieDetail = (state) => state.categories.detail;
export const categoriesStatus = (state) => state.categories.status;
export const categoriesError = (state) => state.categories.error;

export const { clearCategoryDetail, clearCategories, setIdleStatus } =
  categorySlice.actions;

export default categorySlice.reducer;
