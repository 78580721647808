import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) || undefined;
}

const getProducts = (params) => {
    return axiosClient.get("products", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getProductDetail = (id) => {
    return axiosClient.get("products/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getProductsByUnit = (params) => {
    return axiosClient.get("products-detail", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postProduct = (data) => {
    return axiosClient.post("products", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putProduct = (data) => {
    return axiosClient.put("products/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putProductStatus = (id, data) => {
    return axiosClient.put("products-status/" + id, data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getProducts,
    getProductDetail,
    postProduct,
    putProduct,
    putProductStatus,
    getProductsByUnit
}