import axiosClient,  { getToken } from "../axiosClient"


const getLogs = ({url , params}) => {
  return axiosClient.get(url, {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export { getLogs };
