import axiosClient,  { getToken } from "../axiosClient"
const getNCOs = (params) => {
    return axiosClient.get("members", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getNCODetail = (id) => {
    return axiosClient.get("members/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postNCO = (data) => {
    return axiosClient.post("members", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getNOs = (params) => {
    return axiosClient.get("nutrition-clubs", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putNCO = (data) => {
    return axiosClient.put("members/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getType = (params) => {
    return axiosClient.get("enums/nutrition-club-customer-levers", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getNCOs,
    getNCODetail,
    postNCO,
    getNOs,
    putNCO,
    getType
}