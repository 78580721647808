import axiosClient,  { getToken } from "../axiosClient"

const login = (data) => {
    return axiosClient.post("auth/login", data)
}

const resetPassword = (data) => {
    return axiosClient.post("auth/reset-password", data)
}

const logoutAuth = () => {
    return axiosClient.post("auth/logout", {}, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const changePassword = (token, data) => {
    return axiosClient.post("member-credentials/change-password", data, {
        headers: {
            Authorization: "Bearer " + token
        }
    })
}

const changePasswordFirstTime = (token, data) => {
    return axiosClient.post("member-credentials/change-password-first-time", data, {
        headers: {
            Authorization: "Bearer " + token
        }
    })
}

const activeAccount = (data) => {
    return axiosClient.post("auth/active-account", data)
}

export {
    login,
    resetPassword,
    changePassword,
    changePasswordFirstTime,
    logoutAuth,
    activeAccount
    
}