import axiosClient,  { getToken } from "../axiosClient"

const getHealthAmbassador = (params) => {
    return axiosClient.get("statistics/representative-health-ambassador", { //dai su suc khoe tieu bieu
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getNotiMembers = (params) => {
    return axiosClient.get("statistics/noticeable-members", {  //thanh vien can chu y
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

// const getRepresentativeMembers = (params) => {
//     return axiosClient.get("statistics/representative-member", {  //thanh vien noi bat trong thang
//         params,
//         headers: {
//             Authorization: "Bearer " + getToken()
//         }
//     })
// }

const getRepresentativeMembers = (params) => {
    return axiosClient.get("statistics/members-almost-expired-package", {  //thanh vien co goi dinh dương gan hêt han
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getRepresentativeCustomer = (params) => {
    return axiosClient.get("statistics/representative-customer", {  //Khachs hang noi bat trong thang
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getSoldPackages = (params) => {
    return axiosClient.get("statistics/sold-packages", {  //Tong goi dinh duong
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getMemberBirthday = (params) => {
    return axiosClient.get("statistics/birthday-in-month", {  //Nguoi tham gia có goi dinh duong trong tháng
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getSoldProducts = (params) => {
    return axiosClient.get("statistics/sold-products", {  //Tong san pham da ban
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getHealthAmbassador,
    getNotiMembers,
    getRepresentativeMembers,
    getRepresentativeCustomer,
    getSoldPackages,
    getSoldProducts,
    getMemberBirthday
}