import axiosClient,  { getToken } from "../axiosClient"

const getNCCs = (params) => {
    return axiosClient.get("members", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getNCCDetail = (id) => {
    return axiosClient.get("members/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postNCC = (data) => {
    return axiosClient.post("members", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getNCs = (params) => {
    return axiosClient.get("nutrition-clubs", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putNCC = (data) => {
    return axiosClient.put("members/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getType = (params) => {
    return axiosClient.get("enums/nutrition-club-customer-levers", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getHealthInfo = (params) => {
    return axiosClient.get("health-information", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postHealthInfo = (data) => {
    return axiosClient.post("health-information", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const deleteHealthInfo = (data) => {
    return axiosClient.delete("health-information/" + data.healthInformationId, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getHealthInfoDetail = (params) => {
    return axiosClient.get("health-information/" + params.healthInformationId, {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getPresenters = (params) => {
    return axiosClient.get("invitation", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postStar = (data) => {
    return axiosClient.post("member-star-activities", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const changeStatusNCC = ({idMember , payload}) => {
    return axiosClient.put(`members/${idMember}/status`, payload, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getStatusNCC = () => {
    return axiosClient.get(`enums/member-status`, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}


const addChallengeMember = ({memberId, challengeId}) => {
    return axiosClient.post(`members/${memberId}/challenges/${challengeId}`,{}, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getNCCImage = (memberId) => {
    return axiosClient.get(`members/${memberId}/diary-pictures`, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
 
const postImageMember = (memberId, data)=>{
    return axiosClient.post(`members/${memberId}/diary-pictures`, data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putImageMember = ({memberId ,memberPictureId, payload}) => {
    return axiosClient.put(`members/${memberId}/diary-pictures/${memberPictureId}`, payload, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putAvatarMember = ({memberId, payload}) => {
    return axiosClient.put(`members/${memberId}/avatar`, payload, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const deleteImageMember = (memberId, imageId)=>{
    return axiosClient.delete(`members/${memberId}/diary-pictures/${imageId}`, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const exportData = (moduleName, params, options) => {
    return axiosClient.get(moduleName+"/export", {
        params,
        ...options,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getNCCDetailbyIdentity = (params) => {
    return axiosClient.get("members/personal-identity-number", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const stopChallengeMember = ({memberId, challengeId})=>{
    return axiosClient.post(`members/${memberId}/challenges/stop-challenge/${challengeId}`, {}, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const completeChallengeMember = ({memberId, challengeId})=>{
    return axiosClient.post(`members/${memberId}/challenges/complete-challenge/${challengeId}`, {}, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getNCCStarLog= (params) => {
    return axiosClient.get("member-star-activities", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const addNoteChallenge = ({challengeMemberId, note})=>{
    return axiosClient.post(`challenges/note/${challengeMemberId}`,{}, {
        params: { note: note },
        headers: {
            Authorization: "Bearer " + getToken(),
            'Content-Type': 'application/json'
        }
    })
}

const putNoteChallenge = ({challengeMemberNoteId, note})=>{
    return axiosClient.put(`challenges/note/${challengeMemberNoteId}`,{}, {
        params: { note: note },
        headers: {
            Authorization: "Bearer " + getToken(),
            "content-type": "application/json",
        }
    })
}

const deleteNoteChallenge = (challengeMemberId)=>{
    return axiosClient.delete(`challenges/note/${challengeMemberId}`, {
        headers: {
            Authorization: "Bearer " + getToken(),
            "content-type": "application/json",
        }
    })
}

const getNoteChallenge = (challengeMemberId)=>{
    return axiosClient.get(`challenges/note/${challengeMemberId}`, {
        headers: {
            Authorization: "Bearer " + getToken(),
        }
    })
}

const getNccRelative = (memberId)=>{
    return axiosClient.get(`members/${memberId}/relative`, {
        headers: {
            Authorization: "Bearer " + getToken(),
        }
    })
}


const postRelativeNCC = ({memberId, payload}) => {
    return axiosClient.post(`members/${memberId}/relative`, payload, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putRelativeNCC = ({relativeId, data}) => {
    return axiosClient.put("members/relative/" + relativeId, data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const deleteRelativeNCC = (relativeId) => {
    return axiosClient.delete("members/relative/" + relativeId, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getHealthMemberChart = (memberId) => {
    return axiosClient.get(`health-information/member/${memberId}/chart`, {
        headers: {
            Authorization: "Bearer " + getToken(),
        }
    })
}

const getNCCDetailbyPhone = (params) => {
    return axiosClient.get("members/phone-number", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getNCCDetailHBL = (params) => {
    return axiosClient.get("members/phone-number-personal-identity-number", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getNCCs,
    getNCCDetail,
    postNCC,
    getNCs,
    putNCC,
    getType,
    getHealthInfo,
    postHealthInfo,
    deleteHealthInfo,
    getHealthInfoDetail,
    getPresenters,
    postStar,
    changeStatusNCC,
    getStatusNCC,
    addChallengeMember,
    getNCCImage,
    postImageMember,
    deleteImageMember,
    exportData,
    getNCCDetailbyIdentity,
    putImageMember,
    stopChallengeMember,
    putAvatarMember,
    completeChallengeMember,
    getNCCStarLog,
    addNoteChallenge,
    putNoteChallenge,
    getNoteChallenge,
    deleteNoteChallenge,
    getNccRelative,
    postRelativeNCC,
    putRelativeNCC,
    deleteRelativeNCC,
    getHealthMemberChart,
    getNCCDetailbyPhone,
    getNCCDetailHBL
}