import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
    getHealthAmbassador,
    getNotiMembers,
    getRepresentativeMembers,
    getRepresentativeCustomer,
    getSoldPackages,
    getSoldProducts,
    getMemberBirthday
} from './dashboardApi';
import _ from 'lodash'

const initialState = {
    healthAmbassador: undefined,
    notiMembers: undefined,
    representMembers: undefined,
    representCustomer:undefined,
    soldPackages: undefined,
    soldProducts: undefined,
    status: 'idle',
    soldStatus: 'idle',
    error: undefined,
    memberBirthday: undefined
    
};

export const getHealthAmbassadorAsync = createAsyncThunk(
    'getHealthAmbassador',
    async (params, thunkAPI) => {
        try {
            const response = await getHealthAmbassador(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data?.result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const getNotiMembersAsync = createAsyncThunk(
    'getNotiMembers',
    async (params, thunkAPI) => {
        try {
            const response = await getNotiMembers(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data?.result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const getRepresentativeCustomerAsync = createAsyncThunk(
    'getRepresentativeCustomer',
    async (params, thunkAPI) => {
        try {
            const response = await getRepresentativeCustomer(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data?.result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const getRepresentativeMembersAsync = createAsyncThunk(
    'getRepresentativeMembers',
    async (params, thunkAPI) => {
        try {
            const response = await getRepresentativeMembers(params);
            return response.data?.result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const getSoldPackagesAsync = createAsyncThunk(
    'getSoldPackages',
    async (params, thunkAPI) => {
        try {
            const response = await getSoldPackages(params);
            return response.data?.result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const getSoldProductsAsync = createAsyncThunk(
    'getSoldProducts',
    async (params, thunkAPI) => {
        try {
            const response = await getSoldProducts(params);
            return response.data?.result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const getMemberBirthdayAsync = createAsyncThunk(
    'getMemberBirthdayAsync',
    async (params, thunkAPI) => {
        try {
            const response = await getMemberBirthday(params);
            return response.data?.result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        // setIdleStatus: state => {
        //     state.status = "idle"
        // },
        // clearCustomerDetail: state => {
        //     state.detail = undefined;
        // },
        clearMemberChar: state => {
            state = initialState;
            return state
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getHealthAmbassadorAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getHealthAmbassadorAsync.fulfilled, (state, action) => {
                state.healthAmbassador = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getHealthAmbassadorAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getCustomers"
                    }
                }
            })
            .addCase(getRepresentativeMembersAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getRepresentativeMembersAsync.fulfilled, (state, action) => {
                state.representMembers = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getRepresentativeMembersAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getCustomers"
                    }
                }
            })
            .addCase(getSoldPackagesAsync.pending, (state) => {
                state.soldStatus = 'loading';
            })
            .addCase(getSoldPackagesAsync.fulfilled, (state, action) => {
                let array = action.payload.data
                let totalPackageSold = _.sumBy(array,'totalSold')
                state.soldPackages = totalPackageSold
                state.soldStatus = 'idle';
                state.error = undefined;
            })
            .addCase(getSoldPackagesAsync.rejected, (state, action) => {
                state.soldStatus = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getCustomers"
                    }
                }
            })
            .addCase(getSoldProductsAsync.pending, (state) => {
                state.soldStatus = 'loading';
            })
            .addCase(getSoldProductsAsync.fulfilled, (state, action) => {
                let array = action.payload.data
                let total = _.sumBy(array,'totalSold')
                state.soldProducts = total
                state.soldStatus = 'idle';
                state.error = undefined;
            })
            .addCase(getSoldProductsAsync.rejected, (state, action) => {
                state.soldStatus = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getCustomers"
                    }
                }
            })
            .addCase(getRepresentativeCustomerAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getRepresentativeCustomerAsync.fulfilled, (state, action) => {
                state.representCustomer = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getRepresentativeCustomerAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getCustomers"
                    }
                }
            })
            .addCase(getNotiMembersAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getNotiMembersAsync.fulfilled, (state, action) => {
                state.notiMembers = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getNotiMembersAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getCustomers"
                    }
                }
            })
            .addCase(getMemberBirthdayAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getMemberBirthdayAsync.fulfilled, (state, action) => {
                state.memberBirthday = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getMemberBirthdayAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getCustomers"
                    }
                }
            })
    },
});

export const healthAmbassador = state => state.dashboard.healthAmbassador
export const notiMembers = state => state.dashboard.notiMembers
export const representMembers = state => state.dashboard.representMembers
export const representCustomer = state => state.dashboard.representCustomer
export const soldPackages = state => state.dashboard.soldPackages
export const soldStatus = state => state.dashboard.soldStatus
export const dashboardStatus = state => state.dashboard.status
export const memberBirthday = state => state.dashboard.memberBirthday
export const { clearMemberChar } = dashboardSlice.actions;
export const soldProducts = state => state.dashboard.soldProducts

export default dashboardSlice.reducer;

