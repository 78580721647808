import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import _ from 'lodash';
import {
    getNCCs,
    getNCCDetail,
    postNCC,
    putNCC,
    getPresenters,
    getNCCStarLog,
    getNoteChallenge,
    getNccRelative,
} from './nccAPI';

const initialState = {
    list: undefined,
    starLogs: undefined,
    invitedList: undefined,
    detail: undefined,
    status: 'idle',
    error: undefined,
    noteChallenge: undefined,
    status_challengeNote: 'idle',
    nccRelative: [],
    status_nccRelative: 'idle'
};

export const getNCCsAsync = createAsyncThunk(
    'nccs',
    async (params, thunkAPI) => {
        try {
            const response = await getNCCs(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const getNCCStarAsync = createAsyncThunk(
    'nccsStar',
    async (params, thunkAPI) => {
        try {
            const response = await getNCCStarLog(params);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const getPresenterAsync = createAsyncThunk(
    'presenters',
    async (params, thunkAPI) => {
        try {
            const response = await getPresenters(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const getNCCDetailAsync = createAsyncThunk(
    'nccDetail',
    async (id, thunkAPI) => {
        try {
            const response = await getNCCDetail(id);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const postNCCAsync = createAsyncThunk(
    'postNCC',
    async (data, thunkAPI) => {
        try {
            const response = await postNCC(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const putNCCAsync = createAsyncThunk(
    'putNCC',
    async (data, thunkAPI) => {
        try {
            const response = await putNCC(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const updateStatusNCCAsync = createAsyncThunk(
    'changeStatusNCC',
    async (data, thunkAPI) => {
        try {
            const response = await putNCC(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const getNoteChallengeAsync = createAsyncThunk(
    'noteChallenge',
    async (params, thunkAPI) => {
        try {
            const response = await getNoteChallenge(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const getNCCsRelative = createAsyncThunk(
    'nccs relative',
    async (id, thunkAPI) => {
        try {
            const response = await getNccRelative(id);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const nccSlice = createSlice({
    name: 'ncc',
    initialState,
    reducers: {
        setIdleStatus: state => {
            state.status = "idle"
        },
        clearNCCDetail: state => {
            state.detail = undefined;
            state.invitedList = undefined;
            state.starLog = undefined;
            state.nccRelative = undefined
        },
        clearNCCs: state => {
            state.list = undefined;
        },
        updateInviteList: (state, action) =>{
            state.invitedList =  action.payload;
        },
        updateNCCDetail: (state, action) =>{
            console.log(action);
            state.detail =  action.payload;
        },
        updateRelativeList: (state, action) =>{
            state.nccRelative =  action.payload;
        },
        clearNoteChalleng: state => {
            state.noteChallenge = undefined;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getNCCsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getNCCsAsync.fulfilled, (state, action) => {
                state.list = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getNCCsAsync.rejected, (state, action) => {
                state.status = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu NTG thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getNCCs"
                    }
                }
            })
            .addCase(getNCCDetailAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getNCCDetailAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getNCCDetailAsync.rejected, (state, action) => {
                state.status = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getNCCDetail"
                    }
                }
            })
            .addCase(postNCCAsync.pending, (state) => {
                state.status = 'detail_loading';
            })
            .addCase(postNCCAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(postNCCAsync.rejected, (state, action) => {
                state.status = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Không tạo được người tham gia!")
                    state.error = {
                        payload: action.payload,
                        title: "addNCCDetail"
                    }
                }
            })
            .addCase(putNCCAsync.pending, (state) => {
                state.status = 'update_loading';
            })
            .addCase(putNCCAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                message.success("Cập nhật thành công")
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(putNCCAsync.rejected, (state, action) => {
                state.status = 'idle';
                if (action?.error?.name !== "AbortError") {
                    if(action?.payload?.message){
                        message.error(action.payload.message)
                    }
                    else
                    message.error("Cập nhật thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "putNCC"
                    }
                }
            })
            .addCase(getPresenterAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPresenterAsync.fulfilled, (state, action) => {
                state.list = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getPresenterAsync.rejected, (state, action) => {
                state.status = 'error';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getNCCs"
                    }
                }
            })
            .addCase(updateStatusNCCAsync.fulfilled, (state, action) => {
                message.success("Thay đổi trạng thái thành công!")
                
            })
            .addCase(updateStatusNCCAsync.rejected, (state, action) => {
                message.error("Thay đổi trạng thái thất bại!")
            })
            .addCase(getNCCStarAsync.pending, (state) => {
                state.statusStar = 'loading';
            })
            .addCase(getNCCStarAsync.fulfilled, (state, action) => {
                let array = []
                let result = action.payload?.result
                if(result?.data && result?.data?.length > 0){
                    array = _.map(result?.data, (item, index)=> {
                        let i = index +1
                        return {
                            ...item,
                            oldStar : result?.data[i]?.currentStar || '---'
                        }
                    })
                }
                state.starLog ={
                    ...result,
                    data: array
                }
                state.statusStar = 'idle';
                state.error = undefined;
            })
            .addCase(getNCCStarAsync.rejected, (state, action) => {
                state.statusStar = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu sao thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getNCCs"
                    }
                }
            })
            .addCase(getNoteChallengeAsync.pending, (state) => {
                state.status_challengeNote = 'loading';
            })
            .addCase(getNoteChallengeAsync.fulfilled, (state, action) => {
                state.noteChallenge = action.payload
                state.status_challengeNote = 'idle';
                state.error = undefined;
            })
            .addCase(getNoteChallengeAsync.rejected, (state, action) => {
                state.status_challengeNote = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu ghi chú thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getNCCs"
                    }
                }
            })
            .addCase(getNCCsRelative.pending, (state) => {
                state.status_nccRelative = 'loading';
            })
            .addCase(getNCCsRelative.fulfilled, (state, action) => {
                state.nccRelative = action.payload.result
                state.status_nccRelative = 'idle';
                state.error = undefined;
            })
            .addCase(getNCCsRelative.rejected, (state, action) => {
                state.status_nccRelative = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu người quen thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getNCCs"
                    }
                }
            })
    },
});

export const selectNCCs = state => state.ncc.list
export const selectNCCDetail = state => state.ncc.detail
export const nccsStatus = state => state.ncc.status
export const nccsError = state => state.ncc.error
export const invitedList = state => state.ncc.invitedList
export const starLogs = state => state.ncc.starLog
export const statusStar = state => state.ncc.statusStar
export const statusNotes = state => state.ncc.status_challengeNote
export const noteChallenges = state => state.ncc.noteChallenge
export const nccRelative = state => state.ncc.nccRelative
export const status_nccRelative = state => state.ncc.status_nccRelative

export const { clearNCCDetail, clearNCCs, setIdleStatus, updateInviteList, updateNCCDetail, clearNoteChalleng, updateRelativeList } = nccSlice.actions;

export default nccSlice.reducer;

