import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
    getNCOs,
    getNCODetail,
    postNCO,
    putNCO,
} from './ncoAPI';

const initialState = {
    list: undefined,
    detail: undefined,
    status: 'idle',
    error: undefined,
};

export const getNCOsAsync = createAsyncThunk(
    'ncos',
    async (params, thunkAPI) => {
        try {
            const response = await getNCOs(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const getNCODetailAsync = createAsyncThunk(
    'ncoDetail',
    async (id, thunkAPI) => {
        try {
            const response = await getNCODetail(id);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const postNCOAsync = createAsyncThunk(
    'postNCO',
    async (data, thunkAPI) => {
        try {
            const response = await postNCO(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const putNCOAsync = createAsyncThunk(
    'putNCO',
    async (data, thunkAPI) => {
        try {
            const response = await putNCO(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);


export const ncoSlice = createSlice({
    name: 'nco',
    initialState,
    reducers: {
        setIdleStatus: state => {
            state.status = "idle"
        },
        clearNCODetail: state => {
            state.detail = undefined;
        },
        clearNCOs: state => {
            state.list = undefined;
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getNCOsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getNCOsAsync.fulfilled, (state, action) => {
                state.list = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getNCOsAsync.rejected, (state, action) => {
                state.status = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getNCOs"
                    }
                }
            })
            .addCase(getNCODetailAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getNCODetailAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getNCODetailAsync.rejected, (state, action) => {
                state.status = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getNCODetail"
                    }
                }
            })
            .addCase(postNCOAsync.pending, (state) => {
                state.status = 'detail_loading';
            })
            .addCase(postNCOAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(postNCOAsync.rejected, (state, action) => {
                state.status = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Không tạo được người tham gia!")
                    state.error = {
                        payload: action.payload,
                        title: "addNCODetail"
                    }
                }
            })
            .addCase(putNCOAsync.pending, (state) => {
                state.status = 'update_loading';
            })
            .addCase(putNCOAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                message.success("Cập nhật thành công")
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(putNCOAsync.rejected, (state, action) => {
                state.status = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Cập nhật thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "putNCO"
                    }
                }
            })
    },
});

export const selectNCOs = state => state.nco.list
export const selectNCODetail = state => state.nco.detail
export const ncosStatus = state => state.nco.status
export const ncosError = state => state.nco.error

export const { clearNCODetail, clearNCOs, setIdleStatus } = ncoSlice.actions;

export default ncoSlice.reducer;

