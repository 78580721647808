import axiosClient, { getToken } from "../axiosClient"

const getChallenges = (params) => {
    return axiosClient.get("challenges", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getChallengesMember = ({memberId, params}) => {
    return axiosClient.get(`members/${memberId}/challenges`, {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getChallengeDetail = (id) => {
    return axiosClient.get("challenges/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postChallenge = (data) => {
    return axiosClient.post("challenges", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putChallenge = (id, data) => {
    return axiosClient.put("challenges/" + id, data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putStatusChallenge = (data) => {
    return axiosClient.put(`challenges/${data?.id}/status/${data?.values?.status}`, {}, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const removePackageChallenge = (challengeId, packageId) => {
    return axiosClient.delete(`challenges/${challengeId}/challenge-packages/${packageId}`,{
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
  }
  
  const addPackageChallenge = (challengeId, packageId) => {
    return axiosClient.post(`challenges/${challengeId}/challenge-packages/${packageId}`,{},{
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
  }

  const postImageChallenge = (id, data)=>{
    return axiosClient.post(`challenges/${id}/challenge-pictures`, data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
  }
  
  const deleteImageChallenge = (id, pictureId)=>{
    return axiosClient.delete(`challenges/${id}/challenge-pictures/${pictureId}`,{
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
  }
  
export {
    getChallenges,
    getChallengeDetail,
    postChallenge,
    putChallenge,
    putStatusChallenge,
    removePackageChallenge,
    addPackageChallenge,
    postImageChallenge,
    deleteImageChallenge,
    getChallengesMember
}