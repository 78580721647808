import {
  createAsyncThunk,
  // createAsytopicThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { message } from "antd";
import { getEventDetail, getEvents, postEvent } from "./eventAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
};

export const getEventsAsync = createAsyncThunk(
  "events",
  async (params, thunkAPI) => {
    try {
      const response = await getEvents(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getEventDetailAsync = createAsyncThunk(
  "eventDetail",
  async (id, thunkAPI) => {
    try {
      const response = await getEventDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const postEventAsync = createAsyncThunk(
  "postEvent",
  async (data, thunkAPI) => {
    try {
      const response = await postEvent(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearEventDetail: (state) => {
      state.detail = undefined;
    },
    clearEvents: (state) => {
      state.list = undefined;
    },
    updateEventDetail: (state, action) => {
      state.detail = action.payload;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // ieventluding actions generated by createAsyeventThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getEventsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getEventsAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getEventsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu sự kiện thất bại!");
          state.error = {
            payload: action.payload,
            title: "getEvents",
          };
        }
      })
      .addCase(getEventDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getEventDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload.result;
        state.status = "idle";
        state.error = false;
      })
      .addCase(getEventDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getEventDetail",
          };
        }
      })
      .addCase(postEventAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postEventAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(postEventAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Create Event failed");
          state.error = {
            payload: action.payload,
            title: "addEvent",
          };
        }
      });
  },
});

export const selectEvents = (state) => state.events.list;
export const selectEventDetail = (state) => state.events.detail;
export const eventsStatus = (state) => state.events.status;
export const eventsError = (state) => state.events.error;

export const { clearEventDetail, clearEvents, setIdleStatus, updateEventDetail } =
  eventSlice.actions;

export default eventSlice.reducer;
