import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
    getUsers,
    getUserDetail,
    putUser,
    postUser,
    putUserStatus,
    putPasswordForUser,
    putUserPassword
} from './userAPI';

const initialState = {
    users: undefined,
    detail: undefined,
    status: 'idle',
    error: undefined,
};

export const getUsersAsync = createAsyncThunk(
    'users',
    async (params, thunkAPI) => {
      try{
        const response = await getUsers(params);
        // The value we return becomes the `fulfilled` action payload
        return response.data;
      } catch(error){
        return thunkAPI.rejectWithValue(error.response.data)
      }
    }
);
export const getUserDetailAsync = createAsyncThunk(
    'userDetail',
    async (id, thunkAPI) => {
      try{
        const response = await getUserDetail(id);
        // The value we return becomes the `fulfilled` action payload
        return response.data;
      } catch(error){
        return thunkAPI.rejectWithValue(error.response.data)
      }
    }
);
export const postUserAsync = createAsyncThunk(
    'postUser',
    async (data, thunkAPI) => {
      try{
        const response = await postUser(data);
        return response.data;
      } catch(error){
        return thunkAPI.rejectWithValue(error.response.data)
      }
    }
);
export const putUserAsync = createAsyncThunk(
    'putUser',
    async (data, thunkAPI) => {
      try{
        const response = await putUser(data);
        return response.data;
      } catch(error){
        return thunkAPI.rejectWithValue(error.response.data)
      }
    }
);
export const putUserStatusAsync = createAsyncThunk(
    'putUserStatus',
    async (data, thunkAPI) => {
      try{
        const response = await putUserStatus(data.id, data.values);
        return response.data;
      } catch(error){
        return thunkAPI.rejectWithValue(error.response.data)
      }
    }
);
export const putUserPasswordAsync = createAsyncThunk(
    'putUserPassword',
    async (data, thunkAPI) => {
      try{
        const response = await putUserPassword(data);
        localStorage.removeItem("auth")
        localStorage.removeItem("token")
        window.location.replace("/")
        return response.data;
      } catch(error){
        return thunkAPI.rejectWithValue(error.response.data)
      }
    }
);
export const putPasswordForUserAsync = createAsyncThunk(
    'putPasswordForUser',
    async (data, thunkAPI) => {
      try{
        const response = await putPasswordForUser(data.id, data.values);
        return response.data;
      } catch(error){
        return thunkAPI.rejectWithValue(error.response.data)
      }
    }
);
  
export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
      setIdleStatus: state => {
        state.status = "idle"
      },
      clearUserDetail: state => {
        state.detail = undefined;
      },
      clearUsers: state => {
        state.users = undefined;
      }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
      builder
        .addCase(getUsersAsync.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(getUsersAsync.fulfilled, (state, action) => {
          state.users = action.payload
          state.status = 'idle';
          state.error = undefined;
        })
        .addCase(getUsersAsync.rejected, (state, action) => {
          state.status = 'idle';
          if(action?.error?.name !== "AbortError"){
            message.error("Lấy dữ liệu thất bại!")
            state.error = {
              payload: action.payload,
              title: "getUsers"
            }
          }
        })
        .addCase(getUserDetailAsync.pending, (state) => {
          state.status = 'detail_loading';
        })
        .addCase(getUserDetailAsync.fulfilled, (state, action) => {
          state.detail = action.payload
          state.status = 'idle';
          state.error = undefined;
        })
        .addCase(getUserDetailAsync.rejected, (state, action) => {
          state.status = 'idle';
          if(action?.error?.name !== "AbortError"){
            message.error("Lấy dữ liệu thất bại!")
            state.error = {
              payload: action.payload,
              title: "getUser"
            }
          }
        })
        .addCase(postUserAsync.pending, (state) => {
          state.status = 'detail_loading';
        })
        .addCase(postUserAsync.fulfilled, (state, action) => {
          state.detail = action.payload
          state.status = 'done';
          state.error = undefined;
        })
        .addCase(putUserAsync.pending, (state) => {
            state.status = 'update_loading';
        })
        .addCase(putUserAsync.fulfilled, (state, action) => {
          state.detail = action.payload
          message.success("Cập nhật thành công")
          state.status = 'done';
          state.error = undefined;
        })
        .addCase(putUserAsync.rejected, (state, action) => {
          state.status = 'idle';
          if(action?.error?.name !== "AbortError"){
            message.error("Cập nhật thất bại!")
            state.error = {
              payload: action.payload,
              title: "putUser"
            }
          }
        })
        .addCase(putUserStatusAsync.pending, (state) => {
          state.status = 'update_status_loading';
        })
        .addCase(putUserStatusAsync.fulfilled, (state, action) => {
          state.detail = action.payload
          state.status = 'done';
          state.error = undefined;
        })
        .addCase(putUserStatusAsync.rejected, (state, action) => {
          state.status = 'idle';
          if(action?.error?.name !== "AbortError"){
            message.error("Status update failed!")
            state.error = {
              payload: action.payload,
              title: "putUserStatus"
            }
          }
        })
        .addCase(putUserPasswordAsync.pending, (state) => {
          state.status = 'update_password_loading';
        })
        .addCase(putUserPasswordAsync.fulfilled, (state, action) => {
          state.detail = action.payload
          state.status = 'done';
          state.error = undefined;
        })
        .addCase(putUserPasswordAsync.rejected, (state, action) => {
          state.status = 'idle';
          if(action?.error?.name !== "AbortError"){
            if(action?.payload?.message){
              if(action?.payload?.message === "Mật khẩu cũ không chính xác!")
              message.error("Mật khẩu hiện tại không đúng!")
              else{
                message.error(action?.payload?.message)
              }
            }
            else{
              message.error("Thay đổi mật khẩu thất bại!")
            }
            state.error = {
              payload: action.payload,
              title: "putUserPassword"
            }
          }
        })
        .addCase(putPasswordForUserAsync.pending, (state) => {
          state.status = 'update_other_password_loading';
        })
        .addCase(putPasswordForUserAsync.fulfilled, (state, action) => {
          // state.detail = action.payload
          state.status = 'done';
          state.error = undefined;
        })
        .addCase(putPasswordForUserAsync.rejected, (state, action) => {
          state.status = 'idle';
          if(action?.error?.name !== "AbortError"){
            message.error("Thay đổi mật khẩu thất bại!")
            state.error = {
              payload: action.payload,
              title: "putOtherUserPassword"
            }
          }
        })
    },
});

export const selectUsers = state => state.users.users
export const selectUserDetail = state => state.users.detail
export const usersStatus = state => state.users.status
export const usersError = state => state.users.error

export const { clearUserDetail, clearUsers, setIdleStatus } = userSlice.actions;
  
export default userSlice.reducer;
  
