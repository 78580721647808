import axiosClient, { getToken } from "../axiosClient"

const getTopics = (params) => {
    return axiosClient.get("topics", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getTopicDetail = (id) => {
    return axiosClient.get("topics/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postTopic = (data) => {
    return axiosClient.post("topics", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putTopic = (id, data) => {
    return axiosClient.put("topics/" + id, data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putStatusTopic = ({id, values}) => {
    return axiosClient.put("topics/" + id, values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const removePackageTopic = (topicId, packageId) => {
    return axiosClient.delete(`topics/${topicId}/topic-packages/${packageId}`,{
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const addPackageTopic = (topicId, packageId) => {
    return axiosClient.post(`topics/${topicId}/topic-packages/${packageId}`,{},{
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postImageTopic = (topicId, data)=>{
    return axiosClient.post(`topics/${topicId}/topic-pictures`, data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const deleteImageTopic = (topicId, topicPictureId)=>{
    return axiosClient.delete(`topics/${topicId}/topic-pictures/${topicPictureId}`, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putTopicImage = ({topicId, topicPictureId, payload}) => {
    return axiosClient.put(`topics/${topicId}/topic-pictures/${topicPictureId}`, payload, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getTopics,
    getTopicDetail,
    postTopic,
    putTopic,
    putStatusTopic,
    removePackageTopic,
    addPackageTopic,
    postImageTopic,
    deleteImageTopic,
    putTopicImage
}