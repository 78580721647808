import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { login, logoutAuth } from './authAPI';

const initialState = {
  token: (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) || undefined,
  info: (localStorage?.auth && JSON.parse(localStorage.auth)) || undefined,
  status: 'idle',
  isActive: true
};

export const authAsync = createAsyncThunk(
  'login/post',
  async (data, { rejectWithValue }) => {
    try {

      const response = await login(data);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response)
    }
  }
);

export const authSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    logout: (state) => {
      logoutAuth(state.token).then(res=>{
        message.success("Đăng xuất thành công")
      })
      state.token = undefined;
      state.info = undefined;
      localStorage.removeItem("auth")
      localStorage.removeItem("token")
      return state
    },
    updateAuth: (state, action) =>{
      state.status = 'idle';
      // state.token = action.payload.result.accessToken;
      // state.info = action.payload.result;
      // return state
    },
    updateActiveStatus: (state, action) => {
      state.isActive = action.payload
      return state
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(authAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(authAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.token = action.payload.result.accessToken;
        state.info = action.payload.result;
      })
      .addCase(authAsync.rejected, (state, action) => {
        state.status = 'idle';
        let response = action?.payload?.data
        if (response && response.message) {
          let noti = response.message
          if(response.result?.shortContent === "unactived"){
            state.info = {}
            state.token = ""
            state.isActive = false
          }
          message.error(noti)
        }
        else{
          message.error("Lỗi liên kết hệ thống")
        }
      })
  },
});

export const { logout, updateAuth, updateActiveStatus } = authSlice.actions;

export const authInfo = (state) => state.auth.info;
export const isActive = (state) => state.auth.isActive;
export default authSlice.reducer;
