import { Button, notification } from "antd";
import axios from "axios";
import { logoutAuth } from "./Auth/authAPI";

export const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const version = "v1/";
const instance = axios.create({
  baseURL: process.env.REACT_APP_API + version + "nco-panel/",
});

// instance.interceptors.request.use(function (config) {
//     return config;
// }, function (error) {
//     return Promise.reject(error)
// })
const openNotification = () => {
  const key = `open${Date.now()}`;
  notification.open({
    message: "Thông báo",
    description:
      "Có một thiết bị khác đăng nhập vào tài khoản của bạn!",
    key,
  });
};

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      logoutAuth()
      localStorage.removeItem("auth");
      localStorage.removeItem("token");
      window.location.replace("/");
    }
    // if (error?.response?.status === 406) {
    //   openNotification();
    // }

    return Promise.reject(error);
  }
);

export default instance;
