import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getEvents = (params) => {
  return axiosClient.get("events", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getEventDetail = (id) => {
  return axiosClient.get("events/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postEvent = (data) => {
  return axiosClient.post("events", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putEvent = (id, data) => {
  return axiosClient.put("events/" + id, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const removePackageEvent = (eventId, packageId) => {
  return axiosClient.delete(`events/${eventId}/event-packages/${packageId}`,{
      headers: {
          Authorization: "Bearer " + getToken()
      }
  })
}

const addPackageEvent = (eventId, packageId) => {
  return axiosClient.post(`events/${eventId}/event-packages/${packageId}`,{},{
      headers: {
          Authorization: "Bearer " + getToken()
      }
  })
}
const postImageEvent = (eventId, data)=>{
  return axiosClient.post(`events/${eventId}/event-pictures`, data, {
      headers: {
          Authorization: "Bearer " + getToken()
      }
  })
}

const deleteImageEvent = (eventId, eventPictureId)=>{
  return axiosClient.delete(`events/${eventId}/event-pictures/${eventPictureId}`,{
      headers: {
          Authorization: "Bearer " + getToken()
      }
  })
}

const deleteEvent = (eventId)=>{
  return axiosClient.delete(`events/${eventId}`,{
      headers: {
          Authorization: "Bearer " + getToken()
      }
  })
}


export { 
  getEvents, 
  getEventDetail, 
  postEvent, 
  putEvent, 
  removePackageEvent, 
  addPackageEvent,
  postImageEvent,
  deleteEvent,
  deleteImageEvent
 };
