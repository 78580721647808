import axiosClient,  { getToken } from "../axiosClient"

const getCategories = (value) => {
    let params = {tag: value}
    return axiosClient.get("categories/tags", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getProvinces = (params) => {
    return axiosClient.get("provinces", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getDistricts = (provinceId) => {
    return axiosClient.get(`provinces/${provinceId}/districts`, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getProvinces,
    getCategories,
    getDistricts
}