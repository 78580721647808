import axiosClient from "../axiosClient"
const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) || undefined;
}

const getPackages = (params) => {
    return axiosClient.get("packages", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getPackageMember = (idMember) => {
    return axiosClient.get("ncc-packages/members/" + idMember, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })

}

const getPackageMemberV2 = (memberId) => {
    return axiosClient.get(`members/${memberId}/invoices`, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })

}

const getProductsMember = (memberId) => {
    return axiosClient.get(`members/${memberId}/invoice-items`, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })

}

const stopPackageMember = ({memberId,invoiceId,payload}) => {
    return axiosClient.post(`members/${memberId}/invoices/stop-invoice/${invoiceId}`, payload,{
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })

}

const addPackageMember = (payload) => {
    return axiosClient.post("ncc-packages", payload,{
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })

}

const getPackageDetail = (id) => {
    return axiosClient.get("packages/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getPackagesByUnit = (params) => {
    return axiosClient.get("packages-detail", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postPackage = (data) => {
    return axiosClient.post("packages", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putPackage = (data) => {
    return axiosClient.put("packages/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putPackageStatus = (id, data) => {
    return axiosClient.put("packages-status/" + id, data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const removeProductPackage = (packageId, productId) => {
    return axiosClient.delete(`packages/${packageId}/products-groups/${productId}`, {
        headers: { Authorization: "Bearer " + getToken()}
    })
} 

const addProductPackage = (packageId, payload) => {
    return axiosClient.post(`packages/${packageId}/product-groups`, payload, {
        headers: { Authorization: "Bearer " + getToken()}
    })
}
const postImagePackage = (packageId, data)=>{
    return axiosClient.post(`packages/${packageId}/package-pictures`, data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putImagePackage = ({packageId ,packagePictureId, payload}) => {
    return axiosClient.put(`packages/${packageId}/package-pictures/${packagePictureId}`, payload, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const deleteImagePackage = (packageId, imageId)=>{
    return axiosClient.delete(`packages/${packageId}/package-pictures/${imageId}`, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getPointByDuration = (params) => {
    return axiosClient.post("packages/price-point",params, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const addProductMember = (payload) => {
    return axiosClient.post("ncc-packages/product", payload,{
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })

}

export {
    getPackages,
    getPackageDetail,
    postPackage,
    putPackage,
    putPackageStatus,
    getPackagesByUnit,
    getPackageMember,
    removeProductPackage,
    addProductPackage,
    addPackageMember,
    getPackageMemberV2,
    stopPackageMember,
    postImagePackage,
    putImagePackage,
    deleteImagePackage,
    getPointByDuration,
    addProductMember,
    getProductsMember
}