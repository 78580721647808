import { 
    createAsyncThunk, 
    // createAsynoticationThunk, 
    createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { getNotiDetail, getNotis, getNotiTypes, postNoti, putStatusNoti, readAllNoti } from './notiAPI';
import _ from 'lodash'

const initialState = {
    list: {
        data:[],
        total:0,
       
    },
    list_type: undefined,
    detail: undefined,
    status: 'idle',
    error: undefined,
//bell
    countNoti: 0, 
    listNoti: [
        {id:1, title:"Thông báo 1", category:{id:1, name:"", value:""}, is_unread:false, unread: -1},
    ],
    loadingNoti: false , 
    errorNoti: false, 
    totalUnread: 0,
    totalUnreadAll: 0,
    totalNotify:10
};

export const getNotisAsync = createAsyncThunk(
    'notications',
    async (params, thunkAPI) => {
        try {
            const response = await getNotis(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const getNotiType = createAsyncThunk(
    'notications type',
    async (params, thunkAPI) => {
        try {
            const response = await getNotiTypes(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const fetchServiceNoti = createAsyncThunk(
    'notications bell',
    async (params, thunkAPI) => {
        try {
            const response = await getNotis(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const getNotiDetailAsync = createAsyncThunk(
    'noticationDetail',
    async (id, thunkAPI) => {
        try {
            const response = await getNotiDetail(id);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const postNotiAsync = createAsyncThunk(
    'postNoti',
    async (data, thunkAPI) => {
        try {
            const response = await postNoti(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const putNotiStatusAsync = createAsyncThunk(
    'putNotiStatus',
    async (data, thunkAPI) => {
        try {
            const response = await putStatusNoti(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const readAllNotiAsync = createAsyncThunk(
    'readAllNotications',
    async (params, thunkAPI) => {
        try {
            const response = await readAllNoti(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const notiSlice = createSlice({
    name: 'notication',
    initialState,
    reducers: {
        setIdleStatus: state => {
            state.status = "idle"
        },
        clearNotiDetail: state => {
            state.detail = undefined;
        },
        clearNotis: state => {
            state.list = undefined;
        },
        updateNotiDetail: (state, action) => {
            state.detail = action.payload;
        },
        updateTotalUnread: (state, payload) =>{
            state.totalUnread = state.totalUnread -1
            state.totalUnreadAll = state.totalUnreadAll-1
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // inoticationluding actions generated by createAsynoticationThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getNotisAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getNotisAsync.fulfilled, (state, action) => {
                state.list = action.payload.result
                state.totalUnreadAll = action.payload.result?.data?.totalUnread
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getNotisAsync.rejected, (state, action) => {
                state.status = 'error';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thông báo thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getNotis"
                    }
                }
            })
            .addCase(getNotiDetailAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getNotiDetailAsync.fulfilled, (state, action) => {
                state.detail = action.payload.result
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getNotiDetailAsync.rejected, (state, action) => {
                state.status = 'error';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getNotiDetail"
                    }
                }
            })
            .addCase(postNotiAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(postNotiAsync.fulfilled, (state, action) => {
                state.detail = action.payload.result
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(postNotiAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Create Noti failed")
                    state.error = {
                        payload: action.payload,
                        title: "addNoti"
                    }
                }
            })
            .addCase(putNotiStatusAsync.pending, (state) => {
                state.status = 'update_status_loading';
            })
            .addCase(putNotiStatusAsync.fulfilled, (state, action) => {
                state.detail = action.payload.result
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(putNotiStatusAsync.rejected, (state, action) => {
                state.status = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Cập nhật trạng thái thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "putNotiStatus"
                    }
                }
            })
//bell noti
            .addCase(fetchServiceNoti.pending, (state) => {
                state.status_bell = 'loading';
            })
            .addCase(fetchServiceNoti.fulfilled, (state, action) => {
                let res = _.cloneDeep(action.payload.result)
                let listData = _.map(res?.data?.notifications, (item)=>{
                    return {
                        id: item.notificationId, 
                        title: item.title, 
                        category:{ id: item?.type?.value, name: item?.type?.name, value: item.itemId },  //loai category thông bao muon tro toi, value: id của bài viết/thu thach
                        is_unread: !item.isRead,
                        unread: item.isRead ? -1 : 1,
                        createdAt: item?.publishAt || item?.createdAt
                    }
                })
                state = {
                    ...state,
                    listNoti : listData,
                    countNoti : res.totalRecord,
                    totalUnread: res.data?.totalUnread,
                    totalNotify: res.totalRecord,
                    status_bell: 'idle',
                    error : undefined
                }
                return state
            })
            .addCase(fetchServiceNoti.rejected, (state, action) => {
                state.status_bell = 'error';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getNotis"
                    }
                }
            })
            .addCase(getNotiType.fulfilled, (state, action) => {
                let array = _.cloneDeep(action.payload.result)
                
                state.list_type = [
                    {displayName: "Thông báo gần nhất",
                name: "topTen",
                value: -1},
                ...array]
            })
            .addCase(readAllNotiAsync.pending, (state, action) => {
                state.status_bell = "loading"
            })
            .addCase(readAllNotiAsync.fulfilled, (state, action) => {
                let array = _.cloneDeep(state.listNoti)
                let listData = _.map(array, (item)=>{
                    return {
                        ...item,
                        unread: -1 ,
                        is_unread: false,
                    }
                })
                state = {
                    ...state,
                    listNoti : listData,
                    totalUnread: 0,
                    totalUnreadAll: state.totalUnreadAll - state.totalUnread,
                    status_bell: 'idle',
                    error : undefined
                }
                return state
            })
            .addCase(readAllNotiAsync.rejected, (state, action) => {
                state.status_bell = 'error';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getNotis"
                    }
                }
            })
            
    },
});

export const selectNotis = state => state.notification.list
export const selectNotiDetail = state => state.notification.detail
export const notiStatus = state => state.notification.status
export const notiError = state => state.notification.error
export const notiTypes = state => state.notification.list_type

export const { clearNotiDetail, clearNotis, setIdleStatus, updateNotiDetail, updateTotalUnread } = notiSlice.actions;

export default notiSlice.reducer;

