import axiosClient, { getToken } from "../axiosClient"

const getNCDetail = (id) => {
    return axiosClient.get("nutrition-clubs/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getNCs = (params) => {
    return axiosClient.get("nutrition-clubs", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getNCsAll = (params) => {
    return axiosClient.get("nutrition-clubs/all-nc", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getProvinces = (params) => {
    return axiosClient.get("provinces", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getCategories = (value) => {
    let params = {tag: value}
    return axiosClient.get("categories/tags", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putAvatarNC = ({nutritionClubId, payload}) => {
    return axiosClient.put(`nutrition-clubs/${nutritionClubId}/avatar`, payload, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const updateNC = ({nutritionClubId, payload}) => {
    return axiosClient.put(`nutrition-clubs/${nutritionClubId}`, payload, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const changeLocationNC = ({nutritionClubId, payload}) => {
    return axiosClient.post(`nutrition-clubs/${nutritionClubId}/location-change-requests`, payload, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getRequestNCs = ({nutritionClubId, params}) => {
    return axiosClient.get(`nutrition-clubs/${nutritionClubId}/location-change-requests`, {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getNCDetail,
    getNCs,
    getProvinces,
    getCategories,
    getNCsAll,
    putAvatarNC,
    updateNC,
    changeLocationNC,
    getRequestNCs
}