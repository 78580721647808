import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
    getNCs,
    getNCDetail,
    getNCsAll,
    getRequestNCs,
} from './ncAPI';

const initialState = {
    list: undefined,
    detail: undefined,
    status: 'idle',
    error: undefined,
    listLocation: undefined,
    status_location: 'idle',
    request: undefined,
    status_request: 'idle'
};

export const getNCsAsync = createAsyncThunk(
    'ncs',
    async (params, thunkAPI) => {
        try {
            const response = await getNCs(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const getNCsAllAsync = createAsyncThunk(
    'ncs all',
    async (params, thunkAPI) => {
        try {
            const response = await getNCsAll(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const getNCDetailAsync = createAsyncThunk(
    'ncDetail',
    async (id, thunkAPI) => {
        try {
            const response = await getNCDetail(id);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const getRequestNCsAsync = createAsyncThunk(
    'getRequestNcs',
    async (params, thunkAPI) => {
        try {
            const response = await getRequestNCs(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const ncSlice = createSlice({
    name: 'nc',
    initialState,
    reducers: {
        setIdleStatus: state => {
            state.status = "idle"
        },
        clearNCDetail: state => {
            state = initialState
        },
        clearNCs: state => {
            state.list = undefined;
        },
        updateNCDetail: (state, action) =>{
            state.detail =  action.payload;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getNCsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getNCsAsync.fulfilled, (state, action) => {
                state.list = action.payload.result
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getNCsAsync.rejected, (state, action) => {
                state.status = 'error';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu NDD thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getNCs"
                    }
                }
            })
            .addCase(getNCDetailAsync.pending, (state) => {
                state.status = 'detail_loading';
            })
            .addCase(getNCDetailAsync.fulfilled, (state, action) => {
                state.detail = action.payload.result
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getNCDetailAsync.rejected, (state, action) => {
                state.status = 'error';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getNCDetail"
                    }
                }
            })
            .addCase(getNCsAllAsync.pending, (state) => {
                state.status_location = 'loading';
            })
            .addCase(getNCsAllAsync.fulfilled, (state, action) => {
                state.listLocation = action.payload.result
                state.status_location = 'idle';
                state.error = undefined;
            })
            .addCase(getNCsAllAsync.rejected, (state, action) => {
                state.status_location = 'error';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getNCs"
                    }
                }
            })
            .addCase(getRequestNCsAsync.pending, (state) => {
                state.status_request = 'loading';
            })
            .addCase(getRequestNCsAsync.fulfilled, (state, action) => {
                state.request = action.payload
                state.status_request = 'idle';
                state.error = undefined;
            })
            .addCase(getRequestNCsAsync.rejected, (state, action) => {
                state.status_request = 'idle';
                
                if (action?.error?.name !== "AbortError") {
                    //message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getRequestNCs"
                    }
                }
            })
    },
});

export const selectNCsLocation = state => state.nc.listLocation
export const selectNCs = state => state.nc.list
export const selectNCDetail = state => state.nc.detail
export const ncsStatus = state => state.nc.status
export const ncsError = state => state.nc.error
export const status_location = state => state.nc.status_location
export const selectRequestNCs = state => state.nc.request
export const status_request = state => state.nc.status_request

export const { clearNCDetail, clearNCs, setIdleStatus, updateNCDetail } = ncSlice.actions;

export default ncSlice.reducer;

