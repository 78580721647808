import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/Auth/authSlice";
import userReducer from "../features/user/userSlice";
import productReducer from "../features/product/productSlice";
import packageReducer from "../features/packages/packageSlice";
import ncReducer from "./../features/nutritionClub/ncSlice";
import nccReducer from "../features/ncc/nccSlice";
import ncoReducer from "../features/nco/ncoSlice";
import categoryReducer from "../features/category/categorySlice";
import checkinReducer from "../features/checkin/checkinSlice";
import topicReducer from "../features/topic/topicSlice";
import eventReducer from "../features/event/eventSlice";
import locationReducer from "../features/area/locationSlice";
import notificationReducer from "../features/notification/notiSlice";
import challengeReducer from "../features/challenge/challengeSlice";
import simulationReducer from "../features/simulation/simulationSlice";
import dashboardReducer from "../features/dashboard/dashboardSlice";
import productGroupReducer from "../features/productGroup/productGroupSlice";
import logsReducer from "features/activitylogs/logsSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: userReducer,
    products: productReducer,
    productGroups: productGroupReducer,
    packages: packageReducer,
    dashboard: dashboardReducer,
    ncc: nccReducer,
    nco: ncoReducer,
    nc: ncReducer,
    checkin: checkinReducer,
    topics: topicReducer,
    categories: categoryReducer,
    events: eventReducer,
    location: locationReducer,
    notification: notificationReducer,
    challenges: challengeReducer,
    simulations: simulationReducer,
    logs: logsReducer,
  },
});
